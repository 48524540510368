<template>
  <div class="drvers">
    <WalletsList >
      <PageTitle title="WALLETS.TITLE" subtitle="TRANSPORTATION.SUBTITLE">
        <!-- <Button @click="handleAddTransport">
          <span class="fs-16">{{ $t("GLOBAL.ADD_WALLET") }}</span>
          <img class="mx-2" src="@/assets/images/icons/add-user.svg" />
        </Button> -->
      </PageTitle>
    </WalletsList>
  </div>
</template>
<script>
import WalletsList from "@/components/Modules/Wallets/WalletsList/index.vue"
import PageTitle from "@/components/Shared/PageTitle/index.vue"
export default {
  components: {
    WalletsList,
    PageTitle
  },
  methods:{
    handleAddTransport(){
      // alert('test')
      this.$bvModal.show("add-transport")
    }
  }
}
</script>