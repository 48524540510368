<template>
  <div class="wallets">
    <DefaultLayout>
      <slot></slot>
      <FilterBox :roles="roles" />
      <b-table
        class="custom-table"
        :items="items"
        :busy="loading"
        :fields="fields"
        responsive
        :sticky-header="true"
        show-empty
        :tbody-transition-props="transProps"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #empty>
          <div class="text-center p-4">
            {{ $t("GLOBAL.NO_DATA") }}
          </div>
        </template>
        <template #cell(id)="data">
          <div>
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(name)="data">
          <div>
            <div class="table-profile">
              <p>{{ data.item.code }}</p>
            </div>
          </div>
        </template>
        <template #cell(km_price)="data">
          <div>
            {{ data.item.amount }}
          </div>
        </template>
        <template #cell(USER)="data">
          <div>
            {{ data.item.user.name }}
          </div>
        </template>
        <template #cell(fixed_price)="data">
          <div>
            {{ data.item.fixed_price }}
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <span class="status-circle" :class="data.item.status == 1 ? 'active' : 'inactive'">{{
              data.item.status == 1 ? $t("GLOBAL.ACTIVE") : $t("GLOBAL.INACTIVE")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <div class="table-actions">
              <!-- <img src="@/assets/images/icons/table-delete.svg" @click="handleDelete(data.item)" /> -->
              <img src="@/assets/images/icons/plus.png" @click="handleEdit(data.item)" />
            </div>
          </div>
        </template>
      </b-table>
      <CustomPagination
        :current_page="current_page"
        :requestStatus="requestStatus"
        :links="links"
        @paginatePrev="paginatePrev"
        @paginateNext="paginateNext"
        @getPagination="getPagination"
      />
      <EditWallet :wallet="wallet" @editWallet="handleSaveChanges" />
      <AddTransport @addTransport="handleAddTransport" />
      <ModalDetails :user="user" />
    </DefaultLayout>
  </div>
</template>

<script>
import FilterBox from "@/components/Shared/FilterBox/index.vue"
import EditWallet from "@/components/Shared/EditWallet/index.vue"
import AddTransport from "@/components/Shared/AddTransport/index.vue"
import CustomPagination from "@/components/Shared/CustomPagination/index.vue"
import { getWalletsData, getWalletByIdRequest, updateWallet } from "@/api/wallets.js"
import { mapActions } from "vuex"
import ModalDetails from "../../../Shared/EditUserDetails/index.vue"

export default {
  props: {
    type: {
      type: Boolean
    }
  },
  components: {
    FilterBox,
    EditWallet,
    AddTransport,
    ModalDetails,
    CustomPagination
  },
  data() {
    return {
      toggleVal: false,
      logs: [],
      user: {},
      wallet: {},
      transProps: {
        name: "flip-list"
      },
      loading: false,
      default_avatar: require("@/assets/images/user.png"),
      requestStatus: "all",
      links: "",
      current_page: 1,
      total_items: 0,
      per_page: 0,
      roles: [
        {
          id: 1,
          name: "role 1"
        },
        {
          id: 2,
          name: "role 2"
        },
        {
          id: 3,
          name: "role 3"
        }
      ],
      fields: [
        { key: "id", label: "#", sortable: false },
        {
          key: "code",
          label: this.$t("GLOBAL.CODE"),
          sortable: false
        },
        {
          key: "amount",
          label: this.$t("GLOBAL.AMOUNT"),
          sortable: false
        },
        {
          key: "USER",
          label: this.$t("GLOBAL.USER"),
          sortable: false
        },
        {
          key: "description",
          label: this.$t("GLOBAL.DESCRIPTION"),
          sortable: false
        },
        {
          key: "created_at",
          label: this.$t("GLOBAL.created_at"),
          sortable: false
        },
        {
          key: "actions",
          label: this.$t(""),
          sortable: false
        }
      ],
      items: []
    }
  },
  mounted() {
    this.getWallets()
  },
  methods: {
    ...mapActions(["ShowToast"]),
    handleEdit(obj) {
      this.ApiService(getWalletByIdRequest(obj.id))
        .then((res) => {
          this.wallet = res.data.data
          this.$bvModal.show("edit-wallet")
        })
        .catch((err) => {
          this.ShowToast({
            title: this.$t("Error"),
            type: "danger",
            message: this.$t(err)
          })
        })
    },
    handleAddTransport(data) {
      this.ApiService(createTransport(data)).then((res) => {
        if (res.data.status == "success") {
          this.$bvModal.hide("add-user")
          location.reload()
        } else {
          // this.ShowToast({
          //     title: this.$t("Delete User"),
          //     type: "danger",
          //     message: elem
          //   })
          // for (const key of Object.keys(res.data.content)) {
          //   // console.log(`${key}: ${person[key]}`)
          // }
        }
      })
    },
    handleSaveChanges(data) {
      this.loading = true
      this.ApiService(updateWallet(data.id, data.formValues)).then((res) => {
        if (res.data.status == "success") {
          // return res.data
          this.ShowToast({
            title: this.$t("Transport Updated"),
            type: "success",
            message: this.$t("Transport Updated")
          })
          setInterval(()=>{
            window.location.reload()
          }, 500)
          return res
        }
      })
    },
    getWallets() {
      this.loading = true
      this.ApiService(getWalletsData(1))
        .then((res) => {
          if (res.data.status != "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.activeLinks = this.links.slice(0, 4)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  getPagination(v) {
    this.loading = true
    this.ApiService(getWalletsData(v.page)).then((res) => {
      if (res.data.status == "success") {
        this.items = res.data.data.data
        this.current_page = res.data.data.current_page
        this.total_items = res.data.data.total
        this.per_page = res.data.data.per_page
        this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
        this.activeLinks = this.links.slice(0, 4)
        this.loading = false
      }
    })
  },
  paginateNext(v) {
    if (v.c_page != this.links.length) {
      this.loading = true
      this.ApiService(getWalletsData(v.c_page + 1)).then((res) => {
        if (res.data.status == "success") {
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.loading = false
        }
      })
    } else {
      return false
    }
  },
  paginatePrev(v) {
    if (v.c_page != 1) {
      this.loading = true
      this.ApiService(getWalletsData(v.c_page - 1)).then((res) => {
        if (res.data.status == "success") {
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.loading = false
        }
      })
    } else {
      return false
    }
  },
  testPagination(v) {
    alert(v)
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
