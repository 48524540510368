<template>
    <b-modal id="edit-wallet" hide-footer size="lg" centered hide-header>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <div class="w-100 info-container">
          <div class="header">
            <h3>{{ $t("GLOBAL.ADD_CREDIT") }}</h3>
            <!-- <p>{{ $t("ACCOUNTS.ADD_USER_SUBTITLE") }}</p> -->
          </div>
          <div class="form">
            <b-row>
              <b-col>
                <b-form-group class="mb-3">
                  <TextField
                    v-model="formValues.amount"
                    :label="$t('GLOBAL.AMOUNT')"
                    :name="$t('GLOBAL.AMOUNT')"
                    rules="required"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-3">
                  <TextField
                    v-model="formValues.description_ar"
                    :label="$t('GLOBAL.DESCRIPTION_AR')"
                    :name="$t('GLOBAL.DESCRIPTION_AR')"
                    rules="required"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-3">
                  <TextField
                    v-model="formValues.description_en"
                    :label="$t('GLOBAL.DESCRIPTION_EN')"
                    :name="$t('GLOBAL.DESCRIPTION_EN')"
                    rules="required"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <Button @click="handleSaveChanges">{{ $t("GLOBAL.ADD_CREDIT") }}</Button>
                <Button custom-class="mx-3 cancel-btn" @click="handleCancel">{{
                  $t("GLOBAL.CANCEL")
                }}</Button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
  </template>
  <script>
  export default {
    props: {
      wallet: {
        type: Object,
      },
    },
    data() {
      return {
        formValues: {
          amount: "",
          description_ar: "",
          description_en: "",
        },
      }
    },
    methods: {
      handleCancel() {
        this.$bvModal.hide("edit-wallet")
      },
      handleSaveChanges() {
        this.$emit("editWallet", {formValues:this.formValues, id: this.wallet.id})
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  @import "./index.scss";
  </style>
  